<template>
  <v-container fluid class="pt-6 mt-6">
    <Documents
      v-if="$route.params.quotaId"
      :key="rerender"
      :quotaId="`${$route.params.quotaId}`"
      :quotasList="quotasList"
    />
  </v-container>
</template>

<script>
export default {
  name: "DocumentsPage",
  components: {
    Documents: () => import("~cp/Quota/Documents/Documents"),
  },
  props: {
    quotasList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      rerender: 0,
    };
  },
  watch: {
    "$route.params.quotaId"(newVal) {
      if (newVal) {
        this.rerender++;
      }
    },
  },
};
</script>

<style></style>
